import type {Insight} from "../../../models/api/insight/Insight";
import {insights as insights20250114} from "./20250114";

const insights: Array<Insight> = [
    ...insights20250114,
];

export {
    insights,
};
